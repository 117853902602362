import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    en: {
      ml: "Mamamayang Liberal",
      aboutTitle: "About ML Partylist",
      aboutLead: "{0} is a partylist that represents the everyday Filipino.",
      about: "We strongly oppose extrajudicial killings, corruption and government waste, environmental neglect and abuse, and collusion with foreign and private interests. Led by Leila de Lima, Teddy Brawner Baguilat, and Erin Tañada, the ML Partylist aims to promote the rights of workers, farmers, indigenous peoples, youth, women, and LGBTQIA+ to ensure each sector has a voice in Congress.",
      volunteerTitle: "Join us",
      volunteer: "Are you interested in being a part of a movement and supporting our cause? This is your chance to contribute and support our partylist, sign up today!"
    },
    fil: {
      ml: "Mamamayang Liberal",
      aboutTitle: "Tungkol sa ML Partylist",
      aboutLead: "Ang {0} ay partylist na naninindigan para sa sambayanang Pilipino ng lipunan.",
      about: "Mariin nitong tinututulan ang mga extrajudicial killing, katiwalian at pagwawaldas sa gobyerno, pagpapabaya at pag-abuso sa kalikasan, at pakikipagkuntsabahan sa mga dayuhan at pribadong interes. Sa pangunguna nina Leila de Lima, Teddy Brawner Baguilat, at Erin Tañada, layunin ng ML Partylist na itaguyod ang mga karapatan ng mga manggagawa, magsasaka, katutubo, kabataan, kababaihan, at LGBTQIA+ upang masiguro na may boses ang bawat sektor sa Kongreso.",
      volunteerTitle: "Samahan ang ML Partylist",
      volunteer: "Gusto mo bang makibahagi at ituloy ang laban na ating sinimulan? Ito na ang iyong pagkakataon upang samahan ang ating mga kandidato na sasabak sa paparating na eleksyon para sa Kongreso upang mag bigay ng TAPAT NA SERBISYO PARA SA MGA PILIPINO, Halika't makibahagi para sa pagbabago."
    }
});

export default strings;