import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Theme from './types/Theme';
import Strings from './types/Strings';
import Language from './types/Language';

import Home from './pages/Home';
import Nominees from './pages/Nominees';
import Platform from './pages/Platform';

function App() {
  const [theme, setTheme] = useState(Theme.System);
  const [language, setLanguage] = useState(Language.FIL);

  const systemPrefersDark = useMediaQuery({
    query: "(prefers-color-scheme: dark)"
  }, undefined);

  useEffect(() => {
    if (theme === Theme.System) {
      if (systemPrefersDark) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    } else {
      if (theme === Theme.Dark) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }

  }, [theme, systemPrefersDark]);

  useEffect(() => {
    switch(language) {
      case Language.EN:
        Strings.setLanguage("en");
        break;

      case Language.FIL:
        Strings.setLanguage("fil");
        break;
        
    }
  }, [language]);

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={ <Home theme={ theme } language={ language } setTheme={ setTheme } setLanguage={ setLanguage }/> }></Route>
        <Route path="/nominees" element={ <Nominees theme={ theme } language={ language } setTheme = { setTheme } setLanguage={ setLanguage }/> }></Route>
        <Route path="/platform" element={ <Platform theme={ theme } language={ language } setTheme = { setTheme } setLanguage={ setLanguage }/> }></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
