import { Container, Row, Stack, Col, Image, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import Theme from '../types/Theme';
import Strings from '../types/Strings';
import Language from '../types/Language';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Nominee from '../components/Nominee';

import slogan from '../assets/slogan.png';
import ldlPhoto from '../assets/nominees/LDL.png';
import ctbPhoto from '../assets/nominees/CTB.png';
import cetPhoto from '../assets/nominees/CET.png';


interface Props {
  theme: Theme,
  language: Language,
  setTheme: (theme: Theme) => void,
  setLanguage: (language: Language) => void
}

const nominees = [
  {
    name: "Leila de Lima",
    image: ldlPhoto,
    position: "First",
    occupations: [
      "Senator of the Philippines (2016-2022)",
      "Secretary of Justice (2010-2015)",
      "Chairperson of the Commission on Human Rights (2008-2010)"
    ],
    link: "https://leiladelima.ph"
  },
  {
    name: "Teddy Baguilat",
    image: ctbPhoto,
    position: "Second",
    occupations: [
      "Representative of Ifugao (2010-2019)",
      "Governor of Ifugao (2007-2010)",
      "Mayor of Kiangan (1995-2001)"
    ],
    link: "https://www.teddybaguilat.com"
  },
  {
    name: "Erin Tañada",
    image: cetPhoto,
    position: "Third",
    occupations: [
      "Representative of Quezon-4th (2004-2013)"
    ],
    link: "https://erintanada.ph"
  }
]

function Home(props: Props) { 
  return (
    <div>
        <Header theme = { props.theme } language = { props.language } setTheme = { props.setTheme } setLanguage = { props.setLanguage }/>
        <Stack className="align-items-center" direction="vertical">
          <Col className="d-flex justify-content-center">
            <Image src={ slogan } fluid/>
          </Col>
          <Col>
            <Container fluid>
              <Stack direction="vertical">
                <Row className="row p-5 rounded-lg m-5 d-flex justify-content-center">
                  <h1 className="display-5 py-3">{ Strings.aboutTitle }</h1>
                  <p className="about-lead">{ Strings.formatString(Strings.aboutLead, <strong>{Strings.ml} </strong>)}</p>
                  <p className="about">
                    { Strings.about }
                  </p>
                </Row>
              </Stack>
            </Container>
          </Col>
          <Col id="nominees">
            <Container fluid>
              <Row className="row p-5 rounded-lg m-5 d-flex justify-content-center">
                <h1 className="display-5 py-3">Nominees</h1>
                {
                  nominees.map((nominee) => {
                    return (
                      <Nominee theme={ props.theme } name={ nominee.name } image={ nominee.image } position={ nominee.position } occupations={ nominee.occupations } link= { nominee.link }/>
                    )
                  })
                }
              </Row>
            </Container>
          </Col>
          <Col>
            <Container fluid>
              <Row className="p-5 rounded-lg m-5 d-flex justify-content-center">
                <h1 className="display-5 py-3">{ Strings.volunteerTitle }</h1>
                <Stack direction="horizontal" gap={3}>
                  <div className="align-self-start">
                    <p className="about">
                      { Strings.volunteer }
                    </p>
                    <Button className="justify-content-center" href="https://docs.google.com/forms/d/e/1FAIpQLScPV4Abcna28p_sJ5WA6d2sgphK3uspj0hiCpjDhE6Me469Gg/viewform" target="_blank" rel="noopener noreferrer">Sign up</Button>
                  </div>
                  <div className="player-wrapper">
                    <ReactPlayer className="react-player" url={"./videos/volunteer.mp4"} controls width={"80%"} height={"80%"}></ReactPlayer>
                  </div>
                </Stack>
              </Row>
            </Container>
          </Col>
        </Stack>
        <Footer/>
    </div>
  )
}

export default Home