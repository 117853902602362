import React from 'react'
import { Card, Stack, Image, Button, Badge } from 'react-bootstrap';
import Themes from '../types/Theme';

interface Props {
    theme: Themes,

    name: string,
    image: string,
    position: string,
    occupations: string[]
    link: string
}

function Nominee(props: Props) {
  return (
    <Card className="m-3" style={{ height: "auto", width: '300px' }} bg={ props.theme === Themes.Dark ? "dark" : "charcoal" } text={ "white" }>
        <Image className="align-self-center" src={ props.image } height={256} width={256} fluid/>
        <Card.Body>
            <Card.Title>{ props.name } <Badge bg="yellow" text="black"><small>{ props.position } Nominee</small></Badge></Card.Title>
            <Card.Text>
                <Stack direction="vertical">
                    {
                        props.occupations.map((occupation) => {
                            return (
                                <small>{ occupation }</small>
                            )
                        })
                    }
                </Stack>
            </Card.Text>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
            <Button className="align-self-end" variant="primary" href={ props.link } target="_blank" rel="noopener noreferrer">Learn more</Button>
        </Card.Footer>
    </Card>
  )
}

export default Nominee