import React from 'react'
import { Container, Row, Col, Stack, Nav, Image } from 'react-bootstrap'
import { Facebook, Twitter, Instagram, Tiktok, Github } from 'react-bootstrap-icons';
import slogan from '../assets/slogan.png';

function Footer() {
  return (
    <footer className="py-4">
      <Container fluid>
        <Row>
          <Stack direction="horizontal" gap={3} className="flex-column flex-sm-row">
            <Col xs={12} sm className="d-flex justify-content-center">
                <Image src={ slogan } width={250}fluid/>
            </Col>
            <Col xs={12} sm className="d-flex justify-content-center align-self-end mb-4">
              <span>View Source Code on <a href="https://github.com/villamorrd/mamamayanglp-web"><Github className="footer-icon" size={32} color="black"/></a></span>
            </Col>
            <Col xs={12} sm>
              <Stack className="align-items-center">
                <Nav>
                  <Nav.Item>
                    <Nav.Link href="https://www.facebook.com/mamamayangliberal">
                      <Facebook className="footer-icon" color="black" size={32} onMouseEnter={ (e) => e }/>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.twitter.com/mlpartylist">
                      <Twitter className="footer-icon" color="black" size={32}/>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.instagram.com/mlpartylist">
                      <Instagram className="footer-icon" color="black" size={32}/>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.tiktok.com/@mamamayangliberal">
                      <Tiktok className="footer-icon" color="black" size={32}/>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <h6>Mamamayang Liberal Party-list</h6>
                <span>Ground Floor AGS Building</span>
                <span>446 EDSA, Guadalupe Viejo</span>
                <span>Makati City, National Capital Region</span>
              </Stack>
            </Col>
          </Stack>

        </Row>
      </Container>
    </footer>
  )
}

export default Footer