import Nav from 'react-bootstrap/Nav'
import { Navbar, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { MoonStarsFill, BrightnessHighFill, CircleHalf, Translate } from 'react-bootstrap-icons';

import logo from '../assets/ml-outlined.png';
import Theme from '../types/Theme';
import Language from '../types/Language';

interface Props {
  theme: Theme,
  language: Language,
  setTheme: (theme: Theme) => void,
  setLanguage: (language: Language) => void
}

function Header(props: Props) {
  const location = useLocation();
  const items = [
    {
      name: "Nominees",
      path: "#nominees"
    },
    {
      name: "Platform",
      path: "/platform",
      disabled: true
    }
  ]

  const onChangeTheme = (theme: Theme): void => {
    props.setTheme(theme);
  }

  const onChangeLang = (language: Language): void => {
    props.setLanguage(language);
  }

  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark" className="navbar">
      <Navbar.Brand href="#" className="navbar-brand">
        <img
            src={ logo }
            width="auto"
            height="64"
            className="d-inline block align-right"
            alt="Mamamayang Liberal Party-list"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {
            items.map((nav, index) => {
              return (
                !nav.disabled ? 
                <Nav.Link key={ index } eventKey={ nav.path } href={ nav.path } active={ location.pathname === nav.path }>{ nav.name }</Nav.Link> : ""
              )
            })
          }
          <Nav.Link href="https://docs.google.com/forms/d/e/1FAIpQLScPV4Abcna28p_sJ5WA6d2sgphK3uspj0hiCpjDhE6Me469Gg/viewform" target="_blank" rel="noopener noreferrer">Volunteer</Nav.Link>
        </Nav>
        <Nav className="me-end">
          <Button onClick={ () => onChangeLang(props.language === Language.EN ? Language.FIL : Language.EN) }><Translate className="me-2"/>{ props.language === Language.EN ? "English" : "Filipino"}</Button>
        </Nav>
        <Nav className="me-end">
          <Dropdown className="navbar-toggle">
            <Dropdown.Toggle id="dropdown-basic">
              {
                props.theme === Theme.Dark ? <MoonStarsFill size={16}/> : props.theme === Theme.Light ? <BrightnessHighFill size={16}/> : <CircleHalf size={16}/>
              }
            </Dropdown.Toggle>

            <Dropdown.Menu align={"end"}>
              <Dropdown.Item active={ props.theme === Theme.Dark } onClick={ () => onChangeTheme(Theme.Dark) }><MoonStarsFill size={16}/> Dark</Dropdown.Item>
              <Dropdown.Item active={ props.theme === Theme.Light } onClick={ () => onChangeTheme(Theme.Light) }><BrightnessHighFill size={16}/> Light</Dropdown.Item>
              <Dropdown.Item active={ props.theme === Theme.System } onClick={ () => onChangeTheme(Theme.System) }><CircleHalf size={16}/> Auto</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header