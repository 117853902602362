import React from 'react'
import Container from 'react-bootstrap/Container'

import Header from '../components/Header';
import Footer from '../components/Footer';

import Theme from '../types/Theme';
import Language from '../types/Language';

interface Props {
  theme: Theme,
  language: Language,
  setTheme: (theme: Theme) => void,
  setLanguage: (language: Language) => void
}

function Nominees(props: Props) {
  return (
    <div>
      <Header theme = { props.theme } language = { props.language } setTheme = { props.setTheme } setLanguage = { props.setLanguage }/>
      <Container>
        
      </Container>
      <Footer/>
    </div>
  )
}

export default Nominees